.custom-rc-tooltip-primary {
  background-color: #2b8fd7 !important;
  border-radius: 8px;
  max-width: 119px;
  opacity: 1;
}

.custom-rc-tooltip-primary .rc-tooltip-content .rc-tooltip-arrow {
  border-top-color: #2b8fd7;
  z-index: 1;
}
.custom-rc-tooltip-primary .rc-tooltip-content .rc-tooltip-arrow-inner {
  border-top-color: #2b8fd7;
}

.custom-rc-tooltip-primary .rc-tooltip-content .rc-tooltip-inner {
  background-color: #2b8fd7;
  border-radius: 8px;
  text-align: center;
  padding: 8px 5px;
  border: solid 1px #2b8fd7;
  filter: drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.25));
}

/* Add more variants here */
