.swipe {
  position: absolute;
}

.cardContainer {
  height: 400px;
  position: fixed;
  width: calc(100% - 20px);
}

.cardContent {
  width: 100%;
  height: 100%;
}

.swipe:last-of-type {
}

.card h3 {
  position: absolute;
  bottom: 0;
  margin: 10px;
  color: #fff;
}

.infoText {
  width: 100%;
  justify-content: center;
  display: flex;
  color: #fff;
  animation-name: popup;
  animation-duration: 800ms;
}

.discover-mode-video-lightbox .ril__toolbarRightSide {
  padding-right: -1px !important;
}

@media screen and (min-width: 760px) {
  .cardContainer {
    position: relative;
    height: 520px;
    width: 348px;
    margin: 0 auto;
    padding: 0 10px;
    left: 0;
    right: 0;
    top: 95px;
    user-select: none
  }
}