.disable-pull{
  /* Disables pull-to-refresh but allows overscroll glow effects. */
  overscroll-behavior: none;
}

.apple-pay-button {
  --apple-pay-button-width: 140px;
  --apple-pay-button-height: 30px;
  --apple-pay-button-border-radius: 5px;
  --apple-pay-button-padding: 5px 0px;
}
