.google-pay-button-container {
  width: 235px;
  position: relative;
  z-index: 2;
}

.google-pay-button-container > .gpay-button-fill > button.gpay-button{
  padding: 14px 53px;
  min-height: 50px;
  border-radius: 8px;
}

.google-pay-button-container > .gpay-button-fill {
  max-height: 50px;
}
