div[data-cy-media-recorder='actions'] div[class*='PhotoVideoToggler'],
div[data-cy-media-recorder='actions'] label[data-cy-switch] {
  display: none;
}

@media (max-width: 768px) {
  div#media-record video#video {
    border-radius: unset;
  }

  div#media-record div.media-recorder-footer {
    position: fixed;
  }
}
