#react-sidebar .sidebar-main {
 overflow-y: scroll;
}

#react-sidebar .mobile {
  transform: translateX(-834px);
}

#react-sidebar .desktop {
  right: 0;
  left: auto;
  transform: translateX(810px);
}

.sidebar-main {
  -webkit-overflow-scrolling: touch;
}

#react-sidebar .sidebar-backdrop {
  background-color: rgba(0, 0, 0, 0.40);
}

.sidebar-main-content ul li {
  font-size: 16px;
  padding: 1.3rem 0.7rem;
  margin: 0 1rem;
  border-top: 1px dashed rgba(0,0,0,0.05);
  border-bottom: 1px dashed rgba(0,0,0,0.05);
}

.sidebar-main-content ul li:hover {
  border-top: 1px dashed rgba(0,0,0,0.25);
  border-bottom: 1px dashed rgba(0,0,0,0.25);
  background-color: transparent !important;
}

#react-sidebar .section-heading {
  font-size: 20px;
  font-weight: 600;
  padding: 1.3rem 0.5rem 0.5rem 1.5rem;
  text-transform: capitalize;
}

.first-back-btn, .second-back-btn {
  font-weight: 600;
  font-size: 20px;
  text-align: center;
  background: none;
}

.first-back-btn span,.second-back-btn span{
  vertical-align: middle;
}

.twilioVerbiage {
  line-height: 19px;
  font-size: 14px;
  margin-top:15px;
}

.sidebar-main-content ul div.twilioVerbiage a {
  color:#2B8FD7;
  text-decoration: underline;
}