.switch {
    position: relative;
    display: inline-block;
    width: 54px;
    height: 28px;
    outline: none;
  }
  .switch input {
    position: absolute;
    top: -99999px;
    left: -99999px;
  }
  .toggle {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: .4s;
    transition: .4s;
    border-radius: 34px;
  }
  .toggle:before {
    position: absolute;
    content: "";
    height: 20px;
    width: 20px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
    border-radius: 50%;
  }
  input:checked + .toggle {
    background-color: #2BEE22;
  }
  input:focus + .toggle {
    box-shadow: 0 0 1px #ccc;
  }
  input:checked + .toggle:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
  }