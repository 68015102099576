/**
* @Author: Daniel Rhees <Daniel>
* @Date:   2017-03-15T16:26:49-07:00
* @Email:  daniel@reflexmedia.com
 * @Last modified by:   Daniel
 * @Last modified time: 2017-08-02T14:47:42-07:00
*/



.animated {
  animation-duration: .25s;
  animation-fill-mode: both; }


@keyframes bounceOutUp {
  from {
    transform: translate3d(0, 0px, 0); }
  to {
    opacity: 0;
    transform: translate3d(0, -60px, 0); } }
.bounceOutUp, .bounceOut--top-center {
  animation-name: bounceOutUp; }

@keyframes bounceInDown {
  from {
    transform: translate3d(0, -60px, 0); }
  to {
    transform: none; }
  }

.bounceInDown, .bounceIn--top-center {
  animation-name: bounceInDown; }

@keyframes bounceOutDown {
  from {
    transform: translate3d(0, 10px, 0); }
  to {
    transform: translate3d(0, 2000px, 0); } }

.bounceOutDown, .bounceOut--bottom-center {
  animation-name: bounceOutDown; }

.toastify {
  z-index: 1000;
  position: fixed;
  color: #999;
  box-sizing: border-box;
  /* show the bar below the top navigation */
  overflow: hidden; }

  /* Modified for MT snackbar */
  .toastify--top-center {
    width: 100%;
    text-align: center;
  }

  .toastify--top-center.container--public {
    top: 0;
  }

  .toastify__closeContainer {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0
  }

  .toastify__close {
    position: absolute;
    top: 0;
    right: 10px;
    padding: 10px 12px;
    cursor: pointer;
    transition: .3s ease; }

    .toastify__close:hover, .toastify__close:focus {
      /*opacity: 1;
      color: #999;
      background: #eee; */
    }

  .toastify__title {
    margin: 0 0 4px 0;
    padding: 0;
    font-size: 18px;
    font-weight: normal; }

  .toastify__body {
    font-size: 16px;
    margin-right: 44px;
  }

  .ril__toolbar{
    height: 60px!important;
  }

.toastify-content {
  box-sizing: border-box;
  position: relative;
  font-size: 16px;
  color: #fff;
  width: 100%;
  /*margin-bottom: 12px;*/
  padding: 10px;
  background: #098A00;
  /*border-left: 8px solid #95a5a6;*/
  box-shadow: 0 1px 10px 0 rgba(0, 0, 0, 0.1), 0 2px 15px 0 rgba(0, 0, 0, 0.05);
  max-width: 600px;
  margin: 0 auto;
}

.toastify-content--warning {
  background-color: #f99900;
}

.toastify-content--error {
  background-color: #CC0000;
}


/*# sourceMappingURL=ReactToastify.css.map */
