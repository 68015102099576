@media (max-width: 768px) {
  .video-gear-icon{
    height: 50px;
  }
}

@keyframes rotation-0 {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes rotation-1 {
  0% {
    transform: rotate(1deg);
  }
  100% {
    transform: rotate(-359deg);
  }
}
