/**
* @Author: Vincent Rafols
* @Email:  vincent@incube8.sg
*/

.buyBtnSWui:hover {
    color: #fff !important;
    background: rgb(28, 115, 177) !important;
    border: #1383d2 !important;
}

.buyBtnSWui:focus, .buyBtnSWui:active {
    color: #1383d2 !important;
    background: transparent !important;
    border: #1383d2 !important;
}

div[data-test-id="profile-offer-price-input"] input::placeholder {
    color: rgb(62, 121, 165);
}


/*Switch UI*/
.switchSWUI {
    position: absolute;
    top: 0;
    right: 0;
    width: 74px;
}

.switchSWUI div {
    width: 72px;
}

.switchSWUI label {
    background-color: #95C7EB !important;
    width: 34px !important;
    height: 28px;
    padding: 1px;
}

.switchSWUI label span {
    width: 28px !important;
    height: 22px !important;
    background: transparent !important;
    margin: 2px !important;
    border-radius: 11px !important;
    border: none !important;
    color: #FFFFFF !important;
    font-size: 12px;
}

.switchSWUI label:first-of-type {
    border-radius: 14px 0 0 14px !important;
}

.switchSWUI label:last-of-type {
    border-radius: 0 14px 14px 0 !important;
}

.switchSWUI label:last-of-type span {
    margin-left: 4px !important;
}

.switchSWUI label input:checked + span {
    background: white !important;
    color: #2B8FD7 !important;
}

.toggleSWUI input:checked + span {
    background-color: #2B8FD7 !important;
    border-color: #2B8FD7 !important;
}


input#promo_code::placeholder {
    color: #C9C9C9 !important;
}