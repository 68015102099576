@keyframes loading-spinner {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
.loading-spinner div {
  left: 29px;
  top: 0;
  position: absolute;
  animation: loading-spinner linear 0.5s infinite;
  background: #2b8fd7;
  width: 8.4px;
  height: 16.8px;
  border-radius: 4.2px / 5.04px;
  transform-origin: 4.2px 32.4px;
}
.loading-spinner div:nth-child(1) {
  transform: rotate(0deg);
  animation-delay: -0.4583333333333333s;
  background: #2b8fd7;
}
.loading-spinner div:nth-child(2) {
  transform: rotate(30deg);
  animation-delay: -0.4166666666666667s;
  background: #2b8fd7;
}
.loading-spinner div:nth-child(3) {
  transform: rotate(60deg);
  animation-delay: -0.375s;
  background: #2b8fd7;
}
.loading-spinner div:nth-child(4) {
  transform: rotate(90deg);
  animation-delay: -0.3333333333333333s;
  background: #2b8fd7;
}
.loading-spinner div:nth-child(5) {
  transform: rotate(120deg);
  animation-delay: -0.2916666666666667s;
  background: #2b8fd7;
}
.loading-spinner div:nth-child(6) {
  transform: rotate(150deg);
  animation-delay: -0.25s;
  background: #2b8fd7;
}
.loading-spinner div:nth-child(7) {
  transform: rotate(180deg);
  animation-delay: -0.20833333333333334s;
  background: #2b8fd7;
}
.loading-spinner div:nth-child(8) {
  transform: rotate(210deg);
  animation-delay: -0.16666666666666666s;
  background: #2b8fd7;
}
.loading-spinner div:nth-child(9) {
  transform: rotate(240deg);
  animation-delay: -0.125s;
  background: #2b8fd7;
}
.loading-spinner div:nth-child(10) {
  transform: rotate(270deg);
  animation-delay: -0.08333333333333333s;
  background: #2b8fd7;
}
.loading-spinner div:nth-child(11) {
  transform: rotate(300deg);
  animation-delay: -0.041666666666666664s;
  background: #2b8fd7;
}
.loading-spinner div:nth-child(12) {
  transform: rotate(330deg);
  animation-delay: 0s;
  background: #2b8fd7;
}
.loading-wrapper {
  width: 66px;
  height: 66px;
  display: inline-block;
  overflow: hidden;
  background: none;
}
.loading-spinner {
  width: 100%;
  height: 100%;
  position: relative;
  transform: translateZ(0) scale(1);
  backface-visibility: hidden;
  transform-origin: 0 0; /* see note above */
}
.loading-spinner div {
  box-sizing: content-box;
}
