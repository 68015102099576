@import url('https://fonts.googleapis.com/css?family=Hind:500');
 .ui-checkbox-btn {
	 position: relative;
	 display: inline-block;
	 padding: 6px 12px;
	 border-radius: 20px;
	 background-color: rgba(255, 0, 0, .6);
	 margin: 6px 5px;
	 cursor: pointer;
	 @media (max-width: 375px) { 
		padding: 6px 11px;			
	 }
}
 .ui-checkbox-btn input {
	 display: none;
}
 .ui-checkbox-btn .icon, .ui-checkbox-btn .text {
	 display: inline-block;
	 vertical-align: top;
	 color: inherit;
}
 .ui-checkbox-btn .text {
	 font-size: 14px;
	 line-height: 22px;
	 padding-top: 2px;
	 padding-left: 4px;
}
 .ui-checkbox-btn.is-checked {
	 border: 1px solid rgba(36,145,222,1);
	 background: rgba(36,145,222,1);
	 color: white;
	 fill: #7b9ea8;
}
 .ui-checkbox-btn.is-unchecked {
	 border: 1px solid rgba(36,145,222,1);
	 background: transparent;
	 color: rgb(36, 145, 222);
	 fill: white;
}
 .icon {
	 position: relative;
	 display: inline-block;
	 width: 20px;
	 height: 24px;
}
 .icon svg {
	 position: absolute;
	 top: 0;
	 right: 0;
	 bottom: 0;
	 left: 0;
	 margin: auto;
	 width: 16px;
	 height: auto;
	 fill: inherit;
}
 .is-checked .icon {
	 color: white;
	 fill: white;
}
 .ui-btn-ping {
	 position: absolute;
	 top: 50%;
	 left: 50%;
	 width: 50%;
	 transform: translate3d(-50%, -50%, 0);
}
 .ui-btn-ping:before {
	 content: '';
	 transform: scale(0, 0);
	 transition-property: background-color transform;
	 transition-timing-function: cubic-bezier(0, 0, 0.2, 1);
	 display: block;
	 padding-bottom: 100%;
	 border-radius: 50%;
	 background-color: rgba(255, 255, 255, .84);
}
 .do-ping .ui-btn-ping:before {
	 transform: scale(2.5, 2.5);
	 transition-duration: 0.35s;
	 background-color: rgba(255, 255, 255, .08);
}
 