.giphy-picker {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100% !important;
    height: 350px !important;
}

.giphy-picker div[data-testid="MasonryLayoutContainer"] {
    width: 95% !important;
}

.giphy-picker-form {

}

.giphy-picker-list {

}

.giphy-picker-item {

}

.giphy-picker input[name="giphy-search"] {
    width: 95% !important;
}

/* Custom for Emoji */
.emoji-mart-category .emoji-mart-category-label span {
    background: none;
    color: #7f7f7f;
}