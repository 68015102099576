/* Normalize-mini: taken from react-native-web */

html {
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  height: 100%;
}

/* for local iFrame issue
html body iframe {
    width: 1px !important;
    height: 1px !important;
}
*/

.darkmode input:-webkit-autofill {
  -webkit-text-fill-color: #ffffff;
  -webkit-box-shadow: 0 0 0px 1000px #162a39 inset;
  border: 1px solid #6c6c6c;
}

.darkmode input:autofill {
  -webkit-text-fill-color: #ffffff;
  -webkit-box-shadow: 0 0 0px 1000px #162a39 inset;
  border: 1px solid #6c6c6c;
}

.darkmode select:-webkit-autofill {
  -webkit-text-fill-color: #ffffff;
  -webkit-box-shadow: 0 0 0px 1000px #162a39 inset;
  border: 1px solid #6c6c6c;
}

.darkmode select:autofill {
  -webkit-text-fill-color: #ffffff;
  -webkit-box-shadow: 0 0 0px 1000px #162a39 inset;
  border: 1px solid #6c6c6c;
}

.darkmode textarea:-webkit-autofill {
  -webkit-text-fill-color: #ffffff;
  -webkit-box-shadow: 0 0 0px 1000px #162a39 inset;
  border: 1px solid #6c6c6c;
}

.darkmode textarea:autofill {
  -webkit-text-fill-color: #ffffff;
  -webkit-box-shadow: 0 0 0px 1000px #162a39 inset;
  border: 1px solid #6c6c6c;
}

body {
  font-family: system-ui, -apple-system, Arial, sans-serif;
  -moz-osx-font-smoothing: grayscale;
  font-size: 16px;
  font-weight: 400;
  color: rgb(89, 89, 89);
  color: rgba(0, 0, 0, 0.65);
  background: #fff;
  margin: 0;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  height: 100%;
}

main {
  margin: 0;
  padding: 0;
}

#root,
#main {
  position: relative;
  min-height: 100%;
  width: 100%;
  margin: 0 auto;
  left: 0;
  right: 0;
  top: 0;
  /*z-index: 800;*/
  /*background: #f5f7fa;*/
  -webkit-transition: left 250ms, right 250ms;
  -o-transition: left 250ms, right 250ms;
  transition: left 250ms, right 250ms;
}

#root {
  height: 100%;
}

body {
  background: #f4f7fa;
}

.contents {
  min-height: 99.97%;
}

button::-moz-focus-inner,
input::-moz-focus-inner {
  border: 0;
  padding: 0;
}

input[type='search']::-webkit-search-cancel-button,
input[type='search']::-webkit-search-decoration {
  display: none;
}

input[type='number'] {
  -moz-appearance: textfield;
}

input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Simple type resets */
html,
button {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  -webkit-text-size-adjust: none;
  -webkit-font-smoothing: antialiased;
}

button {
  cursor: pointer;
}

textarea,
input,
textarea,
select {
  font-style: normal;
  font-size: 1rem;
  line-height: 1.4;
}

strong {
  font-style: normal;
  font-weight: 700;
}

a {
  text-decoration: none;
  color: inherit;
}

a:hover {
  text-decoration: none;
}

a:active,
a:hover {
  outline: 0;
}

/* Simple icon resets */
svg > g {
  stroke: inherit;
}

/* RANDOM STUFF */

.rheostat {
  overflow: visible;
}

.rheostat-background {
  background-color: #fcfcfc;
  border: 1px solid #d8d8d8;
  position: relative;
  border-radius: 12px;
}

.rheostat-progress {
  background-color: #2490de;
  position: absolute;
}

.rheostat-handle {
  background-color: #fff;
  border: 1px solid #2490de;
  border-radius: 28px;
  height: 28px;
  outline: none;
  z-index: 2;
  width: 28px;
  box-shadow: 0 2px 2px rgba(72, 72, 72, 0.2);
}

.rheostat-horizontal {
  height: 8px;
  margin: 16px;
}

.rheostat-horizontal .rheostat-background {
  height: 8px;
  top: 0px;
  width: 100%;
}

.rheostat-horizontal .rheostat-progress {
  height: 8px;
  top: 1px;
}

.rheostat-horizontal .rheostat-handle {
  margin-left: -12px;
  top: -10px;
}

.rheostat-horizontal .rheostat-handle:before,
.rheostat-horizontal .rheostat-handle:after {
  top: 7px;
  height: 16px;
  width: 1px;
}

.rheostat-horizontal .rheostat-handle:before {
  left: 10px;
}

.rheostat-horizontal .rheostat-handle:after {
  left: 13px;
}

.example-appear {
  opacity: 0.01;
}

.example-appear.example-appear-active {
  opacity: 1;
  transition: opacity 0.5s ease-in;
}

.ReactModal__Body--open {
  overflow: hidden;
}

.fade-enter {
  opacity: 0.01;
}

.fade-enter-active {
  opacity: 1;
  transition: opacity 400ms ease-in;
}

.loader-rotate-fix ._loading_overlay_spinner svg {
  animation: vfxrotation 2s infinite linear;
}

@keyframes vfxrotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}

.body-scroll-disabled {
  overflow: hidden !important;
}
