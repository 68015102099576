/**
* @Author: Vincent Rafols
* @Email:  vincent@incube8.sg
*/

.ReactModal__Overlay {
    opacity: 0;
    transition: all 200ms ease-in-out;
}

.ReactModal__Overlay--after-open {
    opacity: 1;
}

.ReactModal__Overlay--before-close {
    opacity: 0;
}